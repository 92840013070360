<template>
  <div>
    <!-- Category Title Loader -->
    <div
      class="page-title q-loader-noicon"
      v-if="!isMobile && showHeaderLoader"
    >
      <div class="row full-width">
        <div class="col-6">
          <q-skeleton width="200px" height="20px" />
          <q-skeleton type="text" width="160px" height="20px" class="q-mt-sm" />
        </div>
        <div class="col-5"> </div>
        <div class="col-1 q-pl-sm q-pr-sm q-pt-md">
          <q-skeleton width="100%" height="20px" />
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="sidebarLeft q-loader-noicon" v-if="showFilterBarLoader">
        <div class="q-mb-md">
          <q-skeleton width="50%" height="25px" />
        </div>
        <div v-for="n in 2" :key="n" class="q-mb-md">
          <q-skeleton width="80%" height="20px" class="q-mb-md" />
          <q-skeleton type="text" width="60%" height="32px" />
          <q-skeleton type="text" width="40%" height="32px" />
          <q-skeleton type="text" width="55%" height="32px" />
          <q-skeleton type="text" width="30%" height="32px" />
          <q-skeleton type="text" width="45%" height="32px" />
        </div>
      </div>
      <div class="sidebarRight" v-if="showListingLoader">
        <ProductSkeleton :isListView="isListView" :size="20" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductListingSkeleton',
  props: {
    showHeaderLoader: {
      type: Boolean,
      default: false,
    },
    showFilterBarLoader: {
      type: Boolean,
      default: false,
    },
    showListingLoader: {
      type: Boolean,
      default: false,
    },
    isListView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductSkeleton: () => import('components/category/ProductSkeleton'),
  },
}
</script>
